import 'react-toastify/dist/ReactToastify.css';

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';

const Contact = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // const [disable, setDisable] = useState(false);

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }
  function notify() {
    toast.success('Submission Form Submitted', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function notifyError() {
    toast.error('Submission Form Not Submitted', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        firstName,
        lastName,
        email,
        phoneNumber,
        message,
      }),
    })
      .then(() => {
        navigate(form.getAttribute('action'));
        notify();
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setMessage('');
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        notifyError();
      });
  };
  return (
    <div>
      <section className='commonSection ContactPage'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h4 className='sub_title'>Contact with us</h4>
              <h2 className='sec_title'>{'Write Us A Message'}</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-8 offset-lg-2 col-sm-12 col-md-10 offset-md-1'>
              <form
                name='contactForm'
                method='post'
                action='/contact/'
                data-netlify='true'
                data-netlify-honeypot='bot-field'
                onSubmit={handleSubmit}
                className='contactFrom'
                id='contactForm'
                // onSubmit={handleOnSubmit}
              >
                <input type='hidden' name='form-name' value='contactForm' />
                <div className='row'>
                  <div className='col-lg-6 col-sm-6'>
                    <input
                      className='input-form required'
                      type='text'
                      name='firstName'
                      id='firstName'
                      placeholder='First Name'
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </div>
                  <div className='col-lg-6 col-sm-6'>
                    <input
                      className='input-form required'
                      type='text'
                      name='lastName'
                      id='lastName'
                      placeholder='Last Name'
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </div>
                  <div className='col-lg-6 col-sm-6'>
                    <input
                      className='input-form required'
                      type='email'
                      name='email'
                      id='email'
                      placeholder='Email Address'
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                  <div className='col-lg-6 col-sm-6'>
                    <input
                      className='input-form'
                      type='text'
                      name='phoneNumber'
                      id='phoneNumber'
                      placeholder='Phone Number'
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </div>
                  <div className='col-lg-12 col-sm-12'>
                    <textarea
                      className='input-form required'
                      name='message'
                      id='message'
                      placeholder='Write Message'
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    ></textarea>
                  </div>
                </div>

                <button
                  // loading={disable}
                  disabled={loading}
                  // onClick={() => setLoading(true)}
                  className='thm-btn banner-two__btn border'
                  type='submit'
                  id='con_submit'
                  // disabled={loading}
                >
                  {loading && (
                    <FontAwesomeIcon icon={faSpinner} className='fa-spin' />
                  )}
                  <span>Send Message</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className='contact-info-one contactBorder'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='contact-info-one__single'>
                <div className='contact-info-one__icon'>
                  <i className='kipso-icon-manager'></i>
                </div>
                <h2 className='contact-info-one__title'>About Us </h2>
                <p className='contact-info-one__text'>
                  Our approach to legal practice reflects the persistently
                  developing world
                  <br /> around us. <br />
                  {/* deliver satisfaction. */}
                </p>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='contact-info-one__single'>
                <div className='contact-info-one__icon'>
                  <i className='kipso-icon-placeholder'></i>
                </div>
                <h2 className='contact-info-one__title'>Our Address</h2>
                <p className='contact-info-one__text'>
                  Flat No. 102 (G2), 2 nd Floor, Reliance Krishna Apartments,
                  Hill Fort Road, Adarsh Nagar, Hyderabad-500004.
                  <br />
                </p>
              </div>
            </div>
            <div className='col-lg-4 '>
              <div className='contact-info-one__single'>
                <div className='contact-info-one__icon'>
                  <i className='kipso-icon-contact'></i>
                </div>
                <h2 className='contact-info-one__title'>Contact Info</h2>
                <p
                  className='contact-info-one__text'
                  style={{
                    paddingBottom: '30px',
                  }}
                >
                  <a href='mailto:management@avadhaniassociates.com'>
                    management@avadhaniassociates.com
                  </a>{' '}
                  <br />
                  <a href='tel:+918074268036'>+91 80742 68036</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.2050212637564!2d78.4695412148535!3d17.40194590696543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9760f1f7a6b7%3A0xacb7d8490990455d!2sReliance%20Krishna%20Apartments%2C%20Hill%20Fort%20Rd%2C%20Band%20Colony%2C%20Adarsh%20Nagar%2C%20Hyderabad%2C%20Telangana%20500004!5e0!3m2!1sen!2sin!4v1644058669498!5m2!1sen!2sin'
        allowfullscreen=''
        loading='lazy'
        className='google-map__contact'
        title='template google map'
      ></iframe>

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Contact;
