import Contact from '../components/contact/contact';
import Footer from '../components/common/footer';
import Layout from '../components/common/layout';
import NavigationMenu from '../components/common/navigationMenu';
import PageHeader from '../components/common/pageHeader';
import React from 'react';

const GalleryPage = () => {
  return (
    <Layout pageTitle='Avadhani & Associates | Contact'>
      <NavigationMenu active='contact' />
      <PageHeader title='Contact' />
      <Contact />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
